import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
import ServerError from "./components/Common/500";
import PageLoader from "./components/Common/pageLoader";
import ImageLoader from "./components/Common/imageLoader";
import VueElementLoading from "vue-element-loading";
import carousel from 'vue-owl-carousel2'
Vue.component("carousel", carousel);
Vue.component("VueElementLoading", VueElementLoading);
Vue.component("ServerError", ServerError);
Vue.component("PageLoader", PageLoader);
Vue.component("ImageLoader", ImageLoader);

import VueNumber from 'vue-number-animation'

Vue.use(VueNumber)

Vue.config.productionTip = false;


// axios.defaults.baseURL = 'http://192.168.52.247:5000/';
// Vue.prototype.baseURL = "http://192.168.52.247:5000/"

axios.defaults.baseURL = "https://wtiadmin.in/";
Vue.prototype.baseURL = "https://wtiadmin.in/";
Vue.prototype.mediaURLOld = "https://wtiadmin.in/i/"
Vue.prototype.mediaURL = "https://wtiadmin.in/i/"
Vue.mixin({
    data() {
        return {
            webpSupported: true,
        };
    },

    created() {
        (async() => {
            // If browser doesn't have createImageBitmap, we can't use webp.
            if (!self.createImageBitmap) {
                this.webpSupported = false;
                return;
            }
            const webpData =
                "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";
            // Retrieve the Image in Blob Format
            const blob = await fetch(webpData).then((r) => r.blob());
            // If the createImageBitmap method succeeds, return true, otherwise false
            this.webpSupported = await createImageBitmap(blob).then(
                () => true,
                () => false
            );
            if (this.webpSupported) {
                Vue.prototype.mediaURL = "https://wtiadmin.in/i/";
            } else {
                Vue.prototype.mediaURL = "https://wtiadmin.in/i/";
            }
        })();
    },
});

new Vue({
    vuetify,
    router,
    render: (h) => h(App),
}).$mount("#app");