<template>
  <div>
    <PageLoader />
    <v-app id="app">
      <!-- <AppHeader v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'" />
      <mainHeader v-if="$vuetify.breakpoint.name !== 'xs' && $route.name == 'payment' "/> -->
      <!-- <marquee
        @click="$router.push('/Events')"
        style="cursor:pointer;background-color:#01060C;margin-top:70px"
      >
        <span
          style="text-shadow: 4px 4px 6px #000000;font-family: TitSemibold;font-size:22px;color:white"
        >
          <span style="font-size:18px"
            >Upcoming Event &nbsp;<v-icon color="white" small
              >mdi-arrow-right</v-icon
            ></span
          >
          " International Art Day and the International Year for Caves and
                Karst "&nbsp;
        </span>
      </marquee> -->
      <div
        :style="{
          'padding-top': $vuetify.breakpoint.name == 'xs' ? '0px' : '0px',
        }"
        style="min-height:90vh"
      >
        <keep-alive>
          <router-view :key="$route.fullPath"></router-view>
        </keep-alive>
      </div>
      <!-- <span style="min-height: 100vh">
        <router-view :key="$route.fullPath" v-bind:storage="sideNav" />
      </span> -->
      <!-- <AppFooter /> -->
    </v-app>
  </div>
</template>
<script>
// import AppHeader from "./layout/appHeader";
// import mainHeader from "./layout/Default";
// import AppFooter from "./layout/appFooter";

import "./assets/styles/fonts.css";
import "./assets/styles/appStyle.css";

export default {
  data() {
    return {
      sideNav: false,
    };
  },
  components: {
    // AppHeader,
    // mainHeader,
    // AppFooter,
  },
};
</script>
